import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Nav = ({ type, slug, divisionName }) => {
  let divisionLink;
  if (slug === "mb" || slug === "ab" || slug === "on") {
    divisionLink = "https://www.grc-rcmp.gc.ca/" + slug + "/index-fra.htm";
  } else if (slug === "bc") {
    divisionLink = "https://cb-bc.grc-rcmp.gc.ca/ViewPage.action?siteNodeId=14&languageId=4";
  } else if (slug === "ca") {
    divisionLink = "https://www.grc-rcmp.gc.ca/fr";
  } else if (slug === "ncr-rcn") {
    divisionLink = "https://www.grc-rcmp.gc.ca/fr/ottawa/accueil";
  } else if (slug === "yt") {
    divisionLink = "https://www.rcmp-grc.gc.ca/fr/yk/accueil";
  } else {
    divisionLink = "https://www.grc-rcmp.gc.ca/fr/" + slug + "/accueil";
  }

  let divisionPronouns;
  if (slug === "ab" || slug === "pe"  || slug === "on") {
    divisionPronouns = " l'";
  } else if (slug === "bc" || slug === "ns" || slug === "sk") {
    divisionPronouns = "la";
  } else if (slug === "mb" || slug === "nb" || slug === "nu" || slug === "qc" || slug === "yt") {
    divisionPronouns = "le";
  } else if (slug === "nt") {
    divisionPronouns = "les";
  } else {
    divisionPronouns = "";
  }

  switch (type) {
    case "division":
      return (
        <nav id="wb-bc" property="breadcrumb">
          <h2>Vous êtes ici&#160;:</h2>
          <div class="container">
            <ol class="breadcrumb">
              <li>
                <a href="https://www.grc-rcmp.gc.ca/fr">
                  <abbr>GRC</abbr>.ca
                </a>
              </li>
              {(() => {
                if (slug !== "ca") {
                  return (
                    <li>
                      <a href={divisionLink}>{divisionName}</a>
                    </li>
                  );
                }
              })()}
            </ol>
          </div>
        </nav>
      );
    case "alert":
      return (
        <nav id="wb-bc" property="breadcrumb">
          <h2>Vous êtes ici&#160;:</h2>
          <div class="container">
            <ol class="breadcrumb">
              <li>
                <a href="https://www.grc-rcmp.gc.ca/fr">
                  <abbr>GRC</abbr>.ca
                </a>
              </li>
              {(() => {
                if (slug !== "ca") {
                  return (
                    <li>
                      <a href={divisionLink}>{divisionName}</a>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (slug === "ca") {
                  return (
                    <li>
                      <Link to={"/" + slug + "/home"}>
                        Alertes d'urgence émises par la <abbr>GRC</abbr>
                      </Link>
                    </li>
                  );
                } else if (slug === "ncr-rcn") {
                  return (
                    <li>
                      <Link to={"/" + slug + "/home"}>
                        Alertes d'urgence émises dans la région de la capitale nationale
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <Link to={"/" + slug + "/home"}>
                        Alertes d'urgence émises par {divisionPronouns} {divisionName}
                      </Link>
                    </li>
                  );
                }
              })()}
            </ol>
          </div>
        </nav>
      );
    default:
      return null;
  }
};

Nav.propTypes = {
  type: PropTypes.string,
};

Nav.defaultProps = {
  type: ``,
};

export default Nav;
