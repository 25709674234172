import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const LandingPage = ({ siteTitle }) => {
  return (
    <main class="container" property="mainContentOfPage" resource="#wb-main" typeof="WebPageElement">
      <h1 id="wb-cont" property="name">
        Emplacements
      </h1>
      <div class="row">
        <div class="col-md-6">
          <div class="list-group">
            <Link to="/ca/acceuil" class="list-group-item">
              À travers le Canada
            </Link>
            <Link to="/ab/acceuil" class="list-group-item">
              Alberta
            </Link>
            <Link to="/bc/acceuil" class="list-group-item">
              Colombie-Britannique
            </Link>
            <Link to="/pe/acceuil" class="list-group-item">
              Île-du-Prince-Édouard
            </Link>
            <Link to="/mb/acceuil" class="list-group-item">
              Manitoba
            </Link>
            <Link to="/nb/acceuil" class="list-group-item">
              Nouveau-Brunswick
            </Link>
            <Link to="/ns/acceuil" class="list-group-item">
              Nouvelle-Écosse
            </Link>
            <Link to="/nu/acceuil" class="list-group-item">
              Nunavut
            </Link>
          </div>
        </div>
        <div class="col-md-6">
          <div class="list-group">
            <Link href="/on/acceuil" class="list-group-item">
              Ontario
            </Link>
            <Link href="/qc/acceuil" class="list-group-item">
              Québec
            </Link>
            <Link href="/ncr-rcn/acceuil" class="list-group-item">
              Région de la capitale nationale
            </Link>
            <Link href="/sk/acceuil" class="list-group-item">
              Saskatchewan
            </Link>
            <Link href="/nl/acceuil" class="list-group-item">
              Terre-Neuve et au Labrador
            </Link>
            <Link href="/nt/acceuil" class="list-group-item">
              Territoires du Nord-Ouest
            </Link>
            <Link href="/yt/acceuil" class="list-group-item">
              Yukon
            </Link>
          </div>
        </div>
      </div>
      <div class="pagedetails">
        <dl id="wb-dtmd">
          <dt>Date de modification&#160;:&#32;</dt>
          <dd>
            <time property="dateModified">2022-01-20</time>
          </dd>
        </dl>
      </div>
    </main>
  );
};

LandingPage.propTypes = {
  siteTitle: PropTypes.string,
};

LandingPage.defaultProps = {
  siteTitle: ``,
};

export default LandingPage;
