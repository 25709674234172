import * as React from "react";
import Layout from "../components/layout/layout.fr";
import Seo from "../components/seo";
import LandingPage from "../components/landing/landing-page.fr";

const IndexPage = () => {
  return (
    <Layout languageToggle={"/home"}>
      <Seo title="Acceuil - Alerte d'urgence de la GRC" />
      <LandingPage />
    </Layout>
  );
};

export default IndexPage;
