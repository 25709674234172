import * as React from "react";
import { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import RCMPlogo from "../../../static/GCWeb/assets/sig-blk-fr.svg";
import Nav from "../nav/nav.fr";

const Header = ({ siteTitle, slug, divisionName, languageToggle, nav }) => {
  
  const [userInfo, setUserInfo] = useState();
  const redirect = "/deconnexion";
 
  // console.log("GATSBY_EXCLUDE_ADMIN_PAGES: ",process.env.GATSBY_ADMIN_PAGES)
  
  useEffect(() => {
    (async () => {
        if (process.env.GATSBY_ADMIN_PAGES==="excluded"){
          // console.log("getting userInfo")
          setUserInfo(await getUserInfo());
        }
      })();
    }, []);

    async function getUserInfo() {
      try {
        const response = await fetch("/.auth/me");
        const payload = await response.json();
        const { clientPrincipal } = payload;
        return clientPrincipal;
      } catch (error) {
        console.error("No profile could be found");
        return undefined;
      }
    }

  return (
    <header>
       <Helmet
        htmlAttributes={{
          lang: 'fr',
        }}
      />
      <div class="container" id="wb-bnr">
        <div class="row">
          {(languageToggle === "/admin/create-alert" || languageToggle === "/admin/confirm" || languageToggle === "/admin/delete")? 
          (""):
          (
            <section class="col-xs-3 col-sm-12 pull-right text-right" id="wb-lng">
              <h2 class="wb-inv">Language selection</h2>
              <ul class="list-inline mrgn-bttm-0">
                <li>
                  <Link to={languageToggle}>English</Link>
                </li>
              </ul>
            </section>
          )}
          <div class="brand col-xs-9 col-sm-5 col-md-4" property="publisher" resource="#wb-publisher" typeof="GovernmentOrganization">
            <Link to="https://www.grc-rcmp.gc.ca/fr">
              <img alt="Gendarmerie royale du Canada" property="logo" src={RCMPlogo}></img>
            </Link>
          </div>
        </div>
      </div>
      <div class="app-bar">
        <div class="container">
          <div class="row">
            <section class="col-xs-12 col-sm-7">
              <h2 class="wb-inv">Nom de l'application Web</h2>
              <p class="h2 mrgn-tp-sm mrgn-bttm-sm">
                Alerte d'urgence de la <abbr>GRC</abbr>
              </p>
            </section>
          {(languageToggle === "/admin/create-alert" || languageToggle === "/admin/confirm" || languageToggle === "/admin/delete")? 
          (""):(
              <nav class="col-sm-5 hidden-xs hidden-print">
                <h2 class="wb-inv">Fermer la session</h2>
                <ul class="app-list-account list-unstyled">
                  <li>
                    {userInfo && (
                      <a href={`/.auth/logout?post_logout_redirect_uri=${redirect}`} class="btn mrgn-tp-md mrgn-bttm-md" style={{ backgroundColor: "#fff", float: "right" }}>
                        <span class="glyphicon glyphicon-off" aria-hidden="true"></span> Fermer la session
                      </a>
                    )}
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
      <Nav type={nav} divisionName={divisionName} slug={slug} />
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  slug: PropTypes.string,
  divisionName: PropTypes.string,
  languageToggle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
